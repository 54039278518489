/* eslint-disable @calm/react-intl/missing-formatted-message*/
import React, { useEffect } from "react";
import { AvatarLink, OwnedFileImage, ConditionalReferenceField } from "./fields";
import { FeatureAvatarListingButton } from "./feature-listing-buttons";
import { ToolbarWithoutDelete } from "./toolbar-without-delete";

import {
  List,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  EditButton,
  SelectInput,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Filter,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";
// import { useDataProvider } from 'react-admin';
// import { crudGetList } from 'react-admin';


const RoomListingFilter = props => (
  <Filter {...props}>
    <TextInput label="Search RoomName" source="name" alwaysOn />
    <TextInput label="Search RoomId" source="hub_sid" alwaysOn />
  </Filter>
);

export const RoomListingEdit = props => {
//   const dataProvider = useDataProvider();

// fetchScenes = () => {
//     // const { crudGetList } = this.props;
//     crudGetList('scenes', {
//       pagination: { page: 1, perPage: 100 }, // Adjust pagination settings as needed
//       sort: { field: 'id', order: 'ASC' }, // Adjust sorting settings as needed
//     })
//       .then((response) => {
//         console.log(response.data, " response =========")
//       })
//       .catch((error) => {
//         console.error('Error fetching scenes:', error);
//       });
//   };

//   useEffect(() => {
//     const allScenes = fetchScenes();
//     console.log("List of all the Scenes", allScenes);
//   }, []);


    return (
  <Edit {...props}>
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
      <TextInput source="name" />
      <TextInput source="description"/>
      <SelectInput source="scene_id"
      label="Select the Scene from the list"
      choices={[
          { id: "notfound", name: "" },
        //   { id: "1778214244240588812", name: "scene1" },
        //   { id: "1778682441502818328", name: "scene2" },
        ]}
      />

      <NumberInput source="max_occupant_count" label="User Limit" />
      <NumberInput source="room_size" label="Room Size" hintText="If User Limit is more than Room Size, other visitors can join with 'Spectate' mode." />
      <p>If User Limit is more than Room Size, other users can join with 'Spectate' mode.</p>
      <TextInput source="user_data.password" label="Password" hintText="Set Password" />
      {/* <SelectInput
        source="state"
        choices={[
          { id: "active", name: "active" },
          { id: "delisted", name: "delisted" }
        ]}
      /> */}
    </SimpleForm>
  </Edit>
)};

const rowStyle = record => ({
  opacity: record.state === "delisted" ? 0.3 : 1
});

export const RoomListingList = props => (
  <List {...props} filters={<RoomListingFilter />} bulkActionButtons={false}>
    <Datagrid rowStyle={rowStyle}>
      <TextField source="name" />
      <TextField source="hub_sid" label="Room Id"/>
      <TextField source="description"/>
      <TextField source="room_size" label="Room Size"/>
      <TextField source="max_occupant_count" label="User Limit"/>
      <TextField source="entry_mode" label="Status"/>
      <TextField source="scene_id"/>
      <TextField source="user_data.password" label="Password"/>
      {/* <TextField source="host"/> */}
      <TextField source="created_by_account_id" label="Creator Id"/>
      <DateField source="inserted_at" label="Creation date"/>
      <DateField source="updated_at"/>
      <DateField source="last_active_at" label="Last Active"/>




{/*
 hub_id                              | bigint                         |           | not null | next_id()
 hub_sid                             | character varying(255)         |           |          |
 slug                                | character varying(255)         |           | not null |
 name                                | character varying(255)         |           | not null |
 default_environment_gltf_bundle_url | character varying(255)         |           |          |
 inserted_at                         | timestamp(0) without time zone |           | not null |
 updated_at                          | timestamp(0) without time zone |           | not null |
 max_occupant_count                  | integer                        |           | not null | 0
 entry_mode                          | hub_entry_mode                 |           | not null | 'allow'::hub_entry_mode
 spawned_object_types                | bigint                         |           | not null | 0
 scene_id                            | bigint                         |           |          |
 host                                | character varying(255)         |           |          |
 created_by_account_id               | bigint                         |           |          |
 scene_listing_id                    | bigint                         |           |          |
 creator_assignment_token            | character varying(255)         |           |          |
 last_active_at                      | timestamp(0) without time zone |           |          |
 embed_token                         | character varying(255)         |           |          |
 embedded                            | boolean                        |           |          | false
 member_permissions                  | integer                        |           |          | 255
 allow_promotion                     | boolean                        |           | not null | false
 description                         | character varying(64000)       |           |          |
 room_size                           | integer                        |           |          |
 user_data                           | jsonb                          |           |          |
 */}







      {/* <TextField source="order" />
      <AvatarLink source="avatar_listing_sid" />
      <ConditionalReferenceField reference="avatar_listings" source="parent_avatar_listing_id">
        <TextField source="name" />
      </ConditionalReferenceField>
      <OwnedFileImage source="base_map_owned_file_id" aspect="square" />
      <OwnedFileImage source="emissive_map_owned_file_id" aspect="square" />
      <OwnedFileImage source="normal_map_owned_file_id" aspect="square" />
      <OwnedFileImage source="orm_map_owned_file_id" aspect="square" />
      <TextField source="attributions" />
      <BooleanField source="allow_remixing" />
      <DateField source="updated_at" />
      <FeatureAvatarListingButton /> */}
      <EditButton />
    </Datagrid>
  </List>
);
